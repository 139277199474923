<template>
  <div>
    <el-tabs v-model="activeTabName">
      <el-tab-pane label="Модерация" name="moderation" />
      <el-tab-pane label="Прошло модерацию" name="reviewed" />
      <!-- <el-tab-pane label="Черновик" name="drafts2" /> -->
      <el-tab-pane label="Проверено" name="approved" />
      <el-tab-pane label="На удаление" name="onDelete" />
      <el-tab-pane label="На измненение" name="onEdit" />
    </el-tabs>
    <div v-if="releaseList.length === 0 && !loaders.releaseList" class="mt7">
      <el-empty description="Нет данных" />
    </div>
    <el-row :gutter="20">
      <!-- <el-col v-for="release in releaseList" :key="release.id" :span="6" class="pa3" style="background-color: #222222; border-radius: 13px;">
        <router-link
          class="router-link"
          :to="{
            name: 'EditDraftRelease',
            params: {
              id: release.releaseId,
            },
            query: {
              requestId: release.id
            }
          }"
        >
          {{ release.description || 'dadasd' }}

        <ReleaseCard :release="release" />
      </el-col> -->
      <el-col v-for="release in releaseList" :key="release.id" class="release-card__wrapper">
        <router-link
          class="router-link"
          :to="{
            name: 'EditDraftRelease',
            params: {
              id: release.releaseId,
            },
            query: {
              requestId: release.id
            }
          }"
        >
          <ReleaseCard :release="release.release" @delete-release="deleteRelease" />
        </router-link>
      </el-col>
    </el-row>
    <div style="display: flex; justify-content: center;">
      <el-pagination
        v-if="totalPages > 1"
        background
        :page-size="pageSize"
        :page-count="totalPages"
        layout="prev, pager, next"
        :total="totalCount"
        @current-change="pageChange"
      />
    </div>
  </div>
</template>
<script>
import {
  ElMessage,
  ElLoading,
} from 'element-plus';
import ReleaseCard from '@/components/cards/ReleaseCard.vue';

export default {
  name: 'ModerationList',
  components: {
    ReleaseCard,
  },
  data: () => ({
    releasesId: {
      approved: 9,
      reviewed: 4,
      moderation: 3,
      onEdit: 7,
      onDelete: 6,
      // drafts2: 1,
    },
    releaseList: [],
    activeTabName: 'moderation',
    loaders: {
      releaseList: false,
    },
    pageNumber: 1,
    totalPages: 0,
    totalCount: 0,
    pageSize: 10,
  }),
  created() {
    this.getReleaseList();
  },
  watch: {
    activeTabName() {
      this.releaseList = [];
      this.pageNumber = 1;
      this.totalPages = 0;
      this.getReleaseList();
    },
  },
  methods: {
    pageChange(page) {
      this.pageNumber = page;
      this.getReleaseList();
    },
    async getReleaseList() {
      const res = {
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      };
      this.loaders.releaseList = true;
      const loading = ElLoading.service({
        lock: true,
        text: 'Загрузка',
        background: 'rgba(0, 0, 0, 0)',
      });
      const { data } = await this.$axios.post(`/release-request/get-release-requests-by-status?status=${this.releasesId[this.activeTabName]}`, res);
      this.loaders.releaseList = false;
      const {
        pageNumber, totalPages, totalCount, items,
      } = data.items;
      this.pageNumber = pageNumber;
      this.totalPages = totalPages;
      this.totalCount = totalCount;
      this.releaseList = items;
      loading.close();
    },
    async deleteRelease(id) {
      await this.$axios.delete(`release-catalog/delete-release-by-id/${id}`)
        .then(() => {
          ElMessage({ grouping: true, message: 'Релиз успешно удалён', type: 'success' });
          this.getReleaseList();
        }).catch((e) => {
          ElMessage({ message: e.response.data[0], type: 'error' });
        });
    },
    // async getRelesedReleases() {
    //   const res = {
    //     pageNumber: this.pageNumber,
    //     pageSize: this.pageSize,
    //   };
    //   this.loaders.releaseList = true;
    //   const loading = ElLoading.service({
    //     lock: true,
    //     text: 'Загрузка',
    //     background: 'rgba(0, 0, 0, 0)',
    //   });
    //   await this.$axios.post('/release-request/get-release-requests-by-moderator', res)
    //     .then((res) => {
    //       const { data } = res;
    //       const {
    //         pageNumber, totalPages, totalCount, items,
    //       } = data;
    //       this.releaseList = items;
    //       this.pageNumber = pageNumber;
    //       this.totalPages = totalPages;
    //       this.totalCount = totalCount;
    //     })
    //     .catch((e) => {
    //       ElMessage({ message: e.response.data[0], type: 'error' });
    //     })
    //     .finally(() => {
    //       this.loaders.releaseList = false;
    //     });
    //   loading.close();
    // },
  },
  computed: {

  },

};
</script>

<style lang="scss">
@media screen and (max-width: 127.77777777777778em) {
  .release-card__wrapper {
    flex-basis:100%!important;
  }
}

//  .release-card {
//   background-color: $primaryWhite;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   padding: 1.9444444444444444em 1.9444444444444444em;
//   border-radius: 1.9444444444444444em;
//   // background-color: grey;
//   margin-bottom:1.1111111111111112em;
//   transition: .3s;

//   &:hover{
//     transform: scale(1.01)
//   }

//   &__header {
//     display: flex;
//     flex-direction: row;

//     .el-image{
//       border-radius: 1em!important;
//       width:8em;
//       height:8em;

//       .image-slot{
//         background-color: #151515;
//         height:100%;
//         display: flex;
//         justify-content: center;
//         align-items: center;
//       }
//     }

//     &--menu-btn{
//       cursor: pointer;
//       transition: 0.2s;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       width:2em;
//       height:2em;
//       border-radius: 50%;

//       font-weight:500;

//       &:hover{
//         background-color: rgba(255,255,255,.2);
//       }
//     }

//     &--info{
//       font-size: 1.3333333333333333em;
//       display: flex;
//       flex-direction: column;
//       justify-content: space-around;
//     }
//     &--name{
//       overflow: hidden;
//       width:40%;
//       white-space: nowrap;
//       text-overflow: ellipsis;
//       font-weight: 500;
//     }
//     &--artist{

//     }
//   }

//   &__data {

//   }
//  }
</style>
